<template>
  <v-bottom-sheet max-width="600" v-model="showing">
    <v-card tile>
      <v-card-title dark>{{event.name}}</v-card-title>
      <v-card-subtitle>{{duration}}<span v-if="event.location"> - {{event.location}}</span></v-card-subtitle>
      <v-card-actions>
        <v-btn color="primary" @click="requestEdit(event)">Edit</v-btn>
        <v-spacer></v-spacer>
        <v-dialog persistent max-width="400" v-model="confirming">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="error" outlined dark v-on="on" v-bind="attrs">Delete</v-btn>
          </template>
          <v-card>
            <v-toolbar dark color="orange">
              Delete Event?
              <v-spacer></v-spacer>
              <v-icon>mdi-alert</v-icon>
            </v-toolbar>
            <v-card-text class="pt-5">
              Are you sure you want to delete this event?
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn color="error" dark @click="requestDelete(event); confirming = false">Yes</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="confirming = false">Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-actions>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import dateTimeUtils from '@/components/services/dateTimeUtils'

export default {
  name: 'eventDetails',
  props: {
    trigger: Boolean,
    event: Object
  },
  data: () => ({
    showing: false,
    confirming: false
  }),
  computed: {
    duration () {
      return dateTimeUtils.toDurationDisplay(this.event)
    }
  },
  methods: {
    requestClose () {
      this.showing = false
    },
    requestEdit (event) {
      this.showing = false
      this.$emit('editRequested', event)
    },
    requestDelete (event) {
      this.showing = false
      this.$emit('deleteRequested', event)
    }
  },
  watch: {
    trigger (on) {
      this.showing = on
    },
    showing (val) {
      this.$emit('update:trigger', val)
    }
  }
}
</script>

<style scoped>

</style>
