<template>
  <div class="mt-4">
    <v-app-bar app dense color="blue" dark class="overflow-visible">
      <v-app-bar-nav-icon @click="requestDrawer"></v-app-bar-nav-icon>
      <v-app-bar-title class="pl-2">
        <v-chip small
                label
                @click="deselectTag(tag)"
                v-for="tag in selectedTags"
                :key="tag.id"
                :color="`${tag.color} lighten-2`"
                dark
                class="ml-2"
        >
          {{tag.text}}
          <v-icon x-small class="ml-2">$delete</v-icon>
        </v-chip>
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <new-item-icon @addTaskRequested="addTaskRequested"
                     @addEventRequested="addEventRequested"
                     @addContactRequested="addContactRequested"
                     @addFileRequested="addFileRequested"></new-item-icon>
    </v-app-bar>
    <v-slide-x-transition group tag="v-list">
      <template v-for="event in filteredEvents">
        <event-list-item :key="event.id" :event="event" @detailsDisplay="showDetails"></event-list-item>
      </template>
    </v-slide-x-transition>
    <event-details :event="selectedEvent" @editRequested="editEvent" @deleteRequested="deleteEvent" :trigger.sync="detailsOpen"></event-details>
  </div>
</template>

<script>
import authenticator from '@/firebase/authenticator'
import { db } from '@/firebase/firestore'
import eventApi from '@/firebase/eventApi'
import EventListItem from '@/components/listItems/EventListItem'
import EventDetails from '@/components/details/EventDetails'
import NewItemIcon from '@/components/icons/NewItemIcon'
import TagCoordinator from '@/components/services/TagCoordinator'

const now = new Date()

export default {
  name: 'Events',
  components: { EventDetails, EventListItem, NewItemIcon },
  created () {
    authenticator.getCurrentUser().then((user) => {
      this.$bind('events', db.collection(`users/${user.id}/events`).orderBy('start'))
      this.user = user
    })
  },
  data: () => ({
    events: [],
    editingEvent: false,
    today: `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDay()}`,
    eventToEdit: { },
    detailsOpen: false,
    selectedEvent: { },
    searchText: '',
    selectedTags: TagCoordinator.selectedTags
  }),
  computed: {
    filteredEvents () {
      return this.filterEvents(this.selectedTags)
    }
  },
  methods: {
    deselectTag (tag) {
      TagCoordinator.deselectTag(tag)
    },
    filterEvents () {
      return this.events.filter(e => TagCoordinator.itemTagSelected(e))
    },
    showDetails (event) {
      this.selectedEvent = event
      this.detailsOpen = true
    },
    editEvent (event) {
      this.$emit('editEvent', event)
    },
    async deleteEvent (event) {
      await eventApi.deleteEvent(event)
    },
    addTaskRequested () {
      this.$emit('addTaskRequested')
    },
    addEventRequested () {
      this.$emit('addEventRequested')
    },
    addContactRequested () {
      this.$emit('addContactRequested')
    },
    addFileRequested () {
      this.$emit('addFileRequested')
    },
    requestDrawer () {
      this.$emit('showDrawer')
    }
  }
}
</script>

<style scoped>

</style>
