<template>
  <v-list-item :style="{'opacity': eventInPast(event) ? .3 : 1}">
    <v-list-item-content @click="showDetails(event)">
      <v-list-item-title>
        {{event.name}}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{formattedStart}}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-icon v-if="!eventInPast(event)">
      <v-container class="ma-0 pa-0 d-flex flex-column" style="align-items: center;">
        <span class="text-caption font-weight-bold text--disabled" style="line-height: .9">{{untilDisplay.count}}</span>
        <span class="font-weight-thin" style="font-size: .5rem; line-height: .7">{{untilDisplay.unit}}</span>
      </v-container>
    </v-list-item-icon>
  </v-list-item>
</template>

<script>
import dateTimeUtils from '@/components/services/dateTimeUtils'

export default {
  name: 'EventListItem',
  props: {
    event: Object
  },
  data: () => ({
    today: dateTimeUtils.getTimelessToday().getTime(),
    now: new Date()
  }),
  created () {
  },
  methods: {
    showDetails (event) {
      this.$emit('detailsDisplay', event)
    },
    eventInPast (event) {
      return event.start < this.now.getTime()
    },
    keepNowUpdated () {
      setInterval(() => {
        console.log('updating time')
        this.now = new Date()
      }, 1000 * 60)
    }
  },
  computed: {
    formattedStart () {
      return dateTimeUtils.toDurationDisplay(this.event)
    },
    untilDisplay () {
      const daysUntilEvent = dateTimeUtils.daysUntil(this.event.start)
      const eventDate = new Date(this.event.start)
      const today = new Date(this.today)
      const eventMonth = eventDate.getMonth()
      const eventYear = eventDate.getFullYear()
      const thisMonth = today.getMonth()
      const thisYear = today.getFullYear()
      const untilDisplayResult = {
        daysUntil: daysUntilEvent
      }
      if (daysUntilEvent === 0) {
        const now = new Date()
        const minutesUntilEvent = Math.floor((eventDate - now) / (1000 * 60))
        const hoursUntilEvent = Math.floor(minutesUntilEvent / 60)
        if (hoursUntilEvent) {
          untilDisplayResult.count = hoursUntilEvent
          untilDisplayResult.unit = `hour${hoursUntilEvent > 1 ? 's' : ''}`
        } else {
          untilDisplayResult.count = minutesUntilEvent
          untilDisplayResult.unit = `min${minutesUntilEvent > 1 ? 's' : ''}`
        }
      } else if (daysUntilEvent <= 14 || (eventMonth === thisMonth && eventYear === thisYear)) {
        untilDisplayResult.count = daysUntilEvent
        untilDisplayResult.unit = `day${untilDisplayResult.count > 1 ? 's' : ''}`
      } else if (eventYear === thisYear || (thisYear !== eventYear && daysUntilEvent > 300)) {
        untilDisplayResult.count = Math.floor(daysUntilEvent / 30)
        untilDisplayResult.count = untilDisplayResult.count === 0 ? 1 : untilDisplayResult.count
        untilDisplayResult.unit = `month${untilDisplayResult.count > 1 ? 's' : ''}`
      } else {
        untilDisplayResult.count = Math.floor(daysUntilEvent / 365)
        untilDisplayResult.unit = `year${untilDisplayResult.count > 1 ? 's' : ''}`
      }
      return untilDisplayResult
    }
  }
}
</script>

<style scoped>

</style>
